import Vue from 'vue';
import forOwn from 'lodash/forOwn';
import { Client } from '@/services';
import { wsPlayerToPlayerInList } from '@/utils/mappers';
import { actions, mutations } from '@/store/helpers';
import STATES from '../../../store/actions/states';
import { Services, M_SET_API_ALERT } from '../../../store/actions/api';
import {
  PLAYERS_REQUEST,
  PLAYERS_ERROR,
  PLAYERS_SUCCESS,
  SET_FILTER,
  SET_SEASON,
  SET_TEAM,
  SET_POSITION,
  CLEAR_PLAYER_LIST,
  ERASE_PLAYERS,
  SET_PAGE,
  PLAYERS_FILTER_CLEAN,
  SET_BAT_HANDEDNESS,
  SET_THROW_HANDEDNESS,
  SET_NAME,
  SET_FILTERS,
  SET_PLAYERS_SEARCH_FORCING,
} from '../../../store/actions/players';
import { Player } from './orm';

const GETTERS = {
  filters: (state) => state.filter,
  forceFindPlayers: (state) => state.forceFindPlayers,
};

const getDefaultFilters = () => {
  return {
    throwHandedness: '',
    batHandedness: '',
    offset: 0,
    position: '',
    status: '',
    team: '',
    name: '',
  };
};

const ACTIONS = {
  initialize({ dispatch }) {
    console.info('Players initializing...');
    console.info('Players initialized.');
  },

  setFetching: actions.setFetching,
  setFetched: actions.setFetched,

  [SET_FILTERS]: ({ commit }, filters) => {
    const newFilters = getDefaultFilters();

    forOwn(newFilters, (value, key) => {
      if (filters.hasOwnProperty(key) && filters[key]) {
        newFilters[key] = filters[key];
      }
    });

    commit(SET_FILTERS, newFilters);
  },

  [SET_SEASON]: ({ commit }, { value }) => {
    const offset = 0;
    commit(SET_FILTER, { key: 'offset', value: offset });
    commit(SET_FILTER, { key: 'team', value: '' });
  },

  [SET_TEAM]: ({ commit }, { value }) => {
    const offset = 0;
    commit(SET_FILTER, { key: 'offset', value: offset });
    commit(SET_FILTER, { key: 'team', value });
  },

  [SET_POSITION]: ({ commit }, { value }) => {
    const offset = 0;
    commit(SET_FILTER, { key: 'offset', value: offset });
    commit(SET_FILTER, { key: 'position', value });
  },

  [SET_BAT_HANDEDNESS]: ({ commit }, { value }) => {
    const offset = 0;
    commit(SET_FILTER, { key: 'offset', value: offset });
    commit(SET_FILTER, { key: 'batHandedness', value });
  },

  [SET_THROW_HANDEDNESS]: ({ commit }, { value }) => {
    const offset = 0;
    commit(SET_FILTER, { key: 'offset', value: offset });
    commit(SET_FILTER, { key: 'throwHandedness', value });
  },

  [SET_NAME]: ({ commit }, { value }) => {
    const offset = 0;
    commit(SET_FILTER, { key: 'offset', value: offset });
    commit(SET_FILTER, { key: 'name', value });
  },

  [SET_PAGE]: ({ commit, state }, { pageNum }) => {
    const offset = (pageNum - 1) * state.maxRecords;
    commit(SET_FILTER, { key: 'offset', value: offset });
  },

  [SET_PLAYERS_SEARCH_FORCING]: ({ state }, { value }) => {
    state.forceFindPlayers = value;
  },
};

const MUTATIONS = {
  setFetching: mutations.setFetching,
  setFetched: mutations.setFetched,

  [SET_FILTER]: (state, { key, value }) => {
    Vue.set(state.filter, key, value);
  },
  [SET_FILTERS]: (state, filters) => {
    // Be aware that replaces whole filter object.
    // If there was other index, besides these returned from getDefaultFilters, it'll be lost.
    Vue.set(state, 'filter', filters);
  },
};

export default {
  namespaced: true,
  state() {
    return {
      filter: getDefaultFilters(),
      forceFindPlayers: false,
    };
  },
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
};
