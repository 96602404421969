export default [
  {
    path: '/players',
    name: 'players',
    meta: { requiresAuth: true },
    components: {
      header: () => import('@/components/core/CoreHeader.vue'),
      drawer: () => import('@/components/core/CoreDrawer.vue'),
      default: () => import(/* webpackChunkName: "playerProfiles" */ '@/modules/player-profiles/PlayersView.vue'),
    },
    props: {
      default: (route) => {
        const { name, season, team, position, batHandedness, throwHandedness } = route.query;
        return {
          name,
          season: Number(season) || undefined,
          team: Number(team) || undefined,
          position,
          batHandedness,
          throwHandedness,
        };
      },
    },
  },
];
